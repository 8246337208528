const Footer = () => {

    const currentYear = new Date().getFullYear()
    
    return (
        <footer className="border border-top mt-5 p-3 text-secondary fixed-bottom bg-light">
            <div className="footer-flex">
                <div>&#169; {currentYear} <a className="purple text-decoration-none" href="https://www.365build.in">	www.365build.in </a></div>
                <div>Email: <a className="purple text-decoration-none" href="mailto:support@365build.in">support@365Build.in</a></div>
                <div>Phone: <a className="purple text-decoration-none" href="tel:9591997072">+91 9591997072</a></div>
            </div>
        </footer>
    )
}
export default Footer
import JK from '../../src/Assets/lakshmi.png'
import dalmiya from '../../src/Assets/dalmiya.jpg'
import sugar from '../../src/Assets/dalmiyasugar.jpg'
import gati from '../../src/Assets/gati.png'
import dtdc from '../../src/Assets/dtdc.png'
import bluedart from '../../src/Assets/bluedart.png'
import firstcry from '../../src/Assets/firstcry.jpg'
import xpress from '../../src/Assets/xpress.png'
import ecomex from '../../src/Assets/ecomex.png'

export const companies = [
    { href: 'https://www.jklakshmicement.com/', src: JK, alt: 'JK Lakshimi Cements' },
    { href: 'https://www.dalmiacement.com/', src: dalmiya, alt: 'Dalmiya Cements' },
    { href: 'https://www.dalmiasugar.com/', src: sugar, alt: 'Dalmiya Sugar' },
    { href: 'https://www.gati.com/', src: gati, alt: 'Gati' },
    { href: 'https://www.dtdc.in/', src: dtdc, alt: 'DTDC' },
    { href: 'https://www.bluedart.com/', src: bluedart, alt: 'Blue Dart' },
    { href: 'https://www.firstcry.com/', src: firstcry, alt: 'First Cry' },
    { href: 'https://www.xpressbees.com/', src: xpress, alt: 'Xpress Bee' },
    { href: 'https://www.ecomexpress.in/', src: ecomex, alt: 'Ecom Express' },
];
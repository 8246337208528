import shree from '../../src/Assets/shree.jpg'
import xiaomi from '../../src/Assets/xiaomi.png'
import Delhivery from '../../src/Assets/Delhivery.png'

export const testimonials = [
    {
      text: "365Build's e-invoice compliance APIs have helped us do away with a lot of manual validations and workarounds. The extra business specific validations they have implemented for us help us a lot to cut down the issues we had faced earlier.",
      author: "Logistics Infra Manager",
      title: "Xiaomi India",
      imgSrc: xiaomi,
      imgAlt: "xiaomi",
    },
    {
      text: "We have rolled out the 365Build e-waybill compliance web application across all our hubs pan-India. This has helped a lot to serve certain adhoc requests that arise repeatedly across our network for e-waybill related compliance purposes. The app is access controlled with 2FA to ensure total data security.",
      author: "Control Tower Ops",
      title: "Delhivery",
      imgSrc: Delhivery,
      imgAlt: "Delhivery",
    },
    {
      text: "We have integrated 365Build's APIs for generation of E-Invoice & E-Waybill at our plants and CFA location pan-India. This system works in conjunction with our Oracle ERP and has enabled us to spend very less effort on compliance and reconciliation.",
      author: "ERP Projects Manager",
      title: "Shree Cements",
      imgSrc: shree,
      imgAlt: "shree cements",
    },
  ]
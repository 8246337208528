import screenshot from '../../src/Assets/screenshot.png'
import { NavLink } from 'react-router-dom'
import Customers from '../Components/Customers'
import Testimonials from '../Components/Testimonials'
import WhyUs from '../Components/WhyUs'
import OurOfferings from '../Components/Offerings'
import Stats from '../Components/Stats'

const Home = () => {

    return (
        <div className="p-3">
            <section className="p-4 p-md-5 mt-5 mb-4 rounded text-body-emphasis bg-body-secondary">
                <div className="col-lg-6 px-0">
                    <h1 className="display-4 fst-italic">
                        Easy E-Waybill & E-invoice
                        compliance for your business.
                    </h1>
                    <p className="lead my-3">
                        Secure APIs & web interfaces to make your GST compliance easy
                        and efficent.
                    </p>
                    <p className="lead mb-0">
                        <NavLink
                            to="/contact-us"
                            className="text-body-emphasis"
                            style={{ color: "black", fontWeight: "bold" }}>
                            Contact us...
                        </NavLink>
                    </p>
                </div>
            </section>
            <hr className="featurette-divider my-5" />
            <section className="row featurette">
                <div className="col-md-5 align-content-center text-center mb-5">
                    <h2 className="featurette-heading lh-1 fw-light mb-4">Our Stats</h2>
                    <Stats />
                </div>
                <div className="col-md-7">
                    <div>
                        <img
                            src={screenshot}
                            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                            width="800"
                            height="800"
                            aria-label="Placeholder: 500x500"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false" />
                    </div>
                </div>
            </section>
            <hr className="featurette-divider my-5" />
            <section className="mb-3">
                <h3 className="text-center fs-1  fw-light mb-5">Our Offerings</h3>
                <OurOfferings />
            </section>
            <hr className="featurette-divider mb-5" />
            <section className="row mb-3">
                <h3 className="text-center fs-1 fw-light mb-5">Why Us?</h3>
                <div className='d-flex justify-content-around flex-wrap'>
                    <WhyUs />
                </div>
            </section>
            <hr className="featurette-divider my-5" />
            <section className='mb-5'>
                <h3 className="text-center fs-1 fw-light mb-5">Client Testimonials</h3>
                <Testimonials />
            </section>
            <hr className="featurette-divider mb-5" />
            <section className='mb-5 pb-5'>
                <h3 className="text-center fs-1 fw-light mb-5">Our Esteemed Customers</h3>
                <Customers />
            </section>
        </div>
    )
}
export default Home
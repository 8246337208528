import { offerings } from "../Data/Offerings"

const OurOfferings = () => {
    return (
      <div className="d-flex justify-content-around flex-wrap">
        {offerings.map((offering, index) => (
          <div key={index} className="card col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 flex-1 mb-5">
            <div className="card-header">
              <h2 className="fw-normal offering-header">
                {offering.title}
              </h2>
            </div>
            <div className="card-body">
              <p className="card-text font-size">
                {offering.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    )
  }
  
  export default OurOfferings
import { testimonials } from "../Data/Testimonails"

const Testimonials = () => {
    return (
      <div>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item faq-item mb-5 border pt-5">
            <div className='d-flex justify-content-center pt-4'>
              <figure className="text-center">
                <blockquote className="text-center">
                  {testimonial.text}
                </blockquote>
                <figcaption className="blockquote-footer fs-6 mt-3">
                  {testimonial.author}
                  <cite title="Source Title">, {testimonial.title}</cite>
                </figcaption>
              </figure>
            </div>
            <img className="avatar" src={testimonial.imgSrc} alt={testimonial.imgAlt} />
          </div>
        ))}
      </div>
    )
  }
  
  export default Testimonials
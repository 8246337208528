export const offerings = [
    {
      title: "GST Verification APIs",
      description: "Validate your customer and vendor credentials with ease.",
    },
    {
      title: "E-Waybill APIs",
      description: "Efficient handling of your e-waybill flow to make your supply chain 100% compliant.",
    },
    {
      title: "E-Invoicing APIs",
      description: "Auto generate E-invoice via integration with your billing system.",
    },
    {
      title: "Web Application Access",
      description: "Worry not if you do not have an ERP. Our system can work standalone via our easy to use web app.",
    },
    {
      title: "GST Reconciliation",
      description: "Application to reconcile your GSTR2 with vendor invoices for 100% ITC claims.",
    },
  ]
export const whyUs = [
    {
      title: "24x7 Support",
      description: "Dedicated support staff available 24x7 on call and via e-mail to support your queries.",
    },
    {
      title: "Easy Integration",
      description: "Experience in integrating across a range of ERP & TMS systems for a quicker roll out.",
    },
    {
      title: "Secure Access",
      description: "All our APIs and web interfaces are encrypted and VAPT tested to ensure absolute data security & integrity.",
    },
  ]
import { useState } from "react"

const Contact = () => {

    const [state, setState] = useState({
        name: "",
        email: "",
        tel: "",
        msg: "",
        showAlertMsg: false
    })

    const disable =
        state.name === "" ||
        state.email === "" ||
        state.tel === "" ||
        state.msg === ""

    const handleState = (event) => {
        const { name, value } = event.target
        setState((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const handleShowMsg = (e) => {
        e.preventDefault()
        if (!disable) {
            setState((prev) => ({
                ...prev,
                showAlertMsg: true
            }))
        }
    }

    return (
        <div>
            <div className="p-5 d-flex flex-wrap font-size justify-content-around">
                <section className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                    <h2 className="mt-3 pb-0 fs-1 fw-light  fst-italic">
                        <i className="flex pb-0 align-self-center bi bi-buildings text-dark me-2" />
                        Office Locations
                    </h2>
                    <hr className="featurette-divider" />
                    <div className="d-flex flex-wrap">
                        <div className="me-4 mb-4">
                            <div className="fw-bold">
                                Registered Head Office
                            </div>
                            <div className="fst-italic fs-4">
                                Gowthaman Basuvaraj
                            </div>
                            <address>
                                18, 24th Main <br />
                                6th Phase, JP Nagar, <br />
                                Bangalore - 560078
                            </address>
                            <div>
                                <a
                                    href="tel:9738830993"
                                    className="text-dark text-decoration-none"
                                >
                                    <i className="flex pb-0 align-self-center bi bi-telephone text-dark me-2" />
                                    +91 9738 830 993
                                </a> <br />
                                <a
                                    href="tel:9611499779"
                                    className="text-dark text-decoration-none"
                                >
                                    <i className="flex pb-0 align-self-center bi bi-telephone text-dark me-2" />
                                    +91 9611 499 779
                                </a><br />
                                <a
                                    href="tel:9844816712"
                                    className="text-dark text-decoration-none"
                                >
                                    <i className="flex pb-0 align-self-center bi bi-telephone text-dark me-2" />
                                    +91 9844 816 712
                                </a>
                                <br />
                                <a
                                    href="mailto:gowthaman@365build.in"
                                    className="text-dark text-decoration-none"
                                >
                                    <i className="flex pb-0 align-self-center bi bi-envelope-at text-dark me-2" />
                                    gowthaman@365build.in
                                </a>
                            </div>
                        </div>
                        <div>
                            <div className="fw-bold">
                                Chennai Sales Office
                            </div>
                            <div className="fst-italic fs-4">
                                Arun Kumar
                            </div>
                            <address>
                                26, Jawahar Street,<br />
                                Arumbakkam,<br />
                                Chennai - 600106
                            </address>
                            <div>
                                <a
                                    href="tel:9611499779"
                                    className="text-dark text-decoration-none"
                                >
                                    <i className="flex pb-0 align-self-center bi bi-telephone text-dark me-2" />
                                    +91 9611 499 779
                                </a><br />
                                <a
                                    href="tel:9952269133"
                                    className="text-dark text-decoration-none"
                                >
                                    <i className="flex pb-0 align-self-center bi bi-telephone text-dark me-2" />
                                    +91 9952 269 133
                                </a><br />
                                <a href="mailto:chennai@365build.in" className="text-dark text-decoration-none">
                                    <i className="flex pb-0 align-self-center bi bi-envelope-at text-dark me-2" />
                                    chennai@365build.in
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="mb-5 mt-4 d-flex">
                        <div className="me-5">
                            <i className="flex pb-0 align-self-center bi bi-geo-alt text-dark me-2" />
                            Bangalore
                        </div>
                        <div>
                            <i className="flex pb-0 align-self-center bi bi-geo-alt text-dark me-2" />
                            Chennai
                        </div>
                    </div>
                </section>
                <section className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 mb-5">
                    <h2 className="mt-3 pb-0 fs-1 fw-light  fst-italic">
                        <i className="flex pb-0 align-self-center bi bi-person-lines-fill text-dark me-2" />
                        Contact Us
                    </h2>
                    <hr className="featurette-divider" />
                    <form>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input type="text" className="form-control" id="name" name="name" onChange={handleState} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email address</label>
                            <input type="email" className="form-control" id="email" name="email" onChange={handleState} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="tel" className="form-label">Telephone</label>
                            <input type="tel" className="form-control" id="tel" name="tel" onChange={handleState} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="msg" className="form-label">What can we do for you?</label>
                            <textarea rows={5} type="text" className="form-control" id="msg" name="msg" onChange={handleState} />
                        </div>
                        <button disabled={disable} type="submit" onClick={handleShowMsg} className="btn btn-primary mb-4">Submit</button>
                    </form>
                    {
                        state.showAlertMsg &&
                        <div className="mt-3 text-success mb-5">
                            We have recieved your message. We will get back to you as soon as we can.
                        </div>
                    }
                </section>
            </div>
        </div >
    )
}
export default Contact


const About = () => {
    return (
        <div className="mt-5">
            <section className="p-5 pt-2 font-size">
                <h1 className="mt-5 pb-0 fs-1 fw-light  fst-italic">
                    <i className="flex pb-0 align-self-center bi bi-book text-dark me-2" />
                    <span className="">About Us</span>
                </h1>
                <hr className="featurette-divider" />
                <p>
                    365Build focuses on providing cloud based enterprise solutions
                    targeting the Construction raw materials Industry.
                    We help our customers overcome some of the major pain points
                    in this line of business, which includes - plants located
                    in remote areas making it infeasible for daily visits by owners,
                    fleet maintenance issues, unskilled workforce which ultimately
                    lead to profit leakages from operations.
                </p>
                <p> Our solution has been built to address these constraints keeping
                    in mind the capabilities of field personnel at such remote sites.
                    The result is a very easy to use system which incorporates invoicing,
                    production control, inventory control and fleet monitoring aspects
                    which form the core of our customer requirements.
                    With the entire system operating on cloud, we ensure that business
                    owners can access their data real-time, 24x7 from anywhere on their
                    mobiles and laptops thereby ensuring full control on their operations.
                </p>
                <p>
                    With over 60 sites across Karnataka, Tamil Nadu and Kerala using our system,
                    365Build continues to focus on improving our product with
                    valuable feedback from our existing customer pool.
                </p>
            </section>
            <section className="mb-5 pb-5">
                <h2 className="mt- p-5 py-0 fs-2 fw-light  fst-italic">
                    <i className="flex pb-0 align-self-center bi bi-people text-dark me-2" />
                    The People
                </h2>
                <hr className="featurette-divider mx-5" />
                <div className="p-5 pt-0 d-flex flex-wrap font-size mb-5">
                    <div className="me-5 mb-4">
                        <div className="fw-bold">
                            Arun - Business Development
                        </div>
                        Arun manages the revenue aspects of the business.<br />
                        That pesky reminder to pay up on that last invoice is probably from his phone.<br />
                        You can catch him here:<br /> <a
                            className="text-decoration-none fst-italic ms-1"
                            href="http://in.linkedin.com/in/arunkumars27/"
                            target="_blank" rel="noreferrer">
                            <i className="flex pb-0 align-self-center bi bi-linkedin text-dark me-2" />
                            arunkumars27
                        </a>
                    </div>
                    <div>
                        <div className="fw-bold">
                            Gowthaman - Technology
                        </div>
                        Gowthaman leads the technology team.<br />
                        Remember that bug in the code?<br />
                        You can catch him here: <br /><a
                            className="text-decoration-none fst-italic ms-1"
                            href="http://in.linkedin.com/in/gowthamanbasuvaraj"
                            target="_blank" rel="noreferrer">
                            <i className="flex pb-0 align-self-center bi bi-linkedin text-dark me-2" />
                            gowthamanbasuvaraj
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About
import { whyUs } from "../Data/WhyUs"

const WhyUs = () => {
    return (
      <div className="row p-3 d-flex justify-content-around">
        {whyUs.map((feature, index) => (
          <div key={index} className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 border rounded shadow p-5 mb-3 mx-">
            <h2 className="fw-normal">
              {feature.title}
            </h2>
            <p className='font-size'>
              {feature.description}
            </p>
          </div>
        ))}
      </div>
    )
  }
  
  export default WhyUs
import { companies } from "../Data/Customers"
import Marquee from "react-fast-marquee"

const Customers = () => {
    return (
        <Marquee className="mb-5" >
            <ul className="d-flex">
                {companies.map((company, index) => (
                    <div key={index} className="align-content-center">
                        <a href={company.href}>
                            <img
                                src={company.src}
                                alt={company.alt}
                                className='me-5 shadow'
                                height={250} 
                                width={250}/>
                        </a>
                    </div>
                ))}
            </ul>
        </Marquee>
    )
}

export default Customers
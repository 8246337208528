import { NavLink } from "react-router-dom"
import logo from '../../src/Assets/logo.jpg'

const Navbar = () => {
    return (
        <header className='mt-4'>
            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-light custom-font">
                <div className="container-fluid">
                    <img src={logo} alt="logo" height="40" width="40" className="me-1" />
                    <a className="navbar-brand custom-font text-dark fw-bold" href="/">
                        EZ-Ewaybill
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i className="bi bi-three-dots text-dark"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav me-auto mb-md-0">
                            <li className="nav-item">
                                <NavLink to='/' className="text-decoration-none nav-link text-dark">
                                    <i className="bi bi-house" />  Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='/about-us' className="text-decoration-none nav-link text-dark">
                                    <i className="bi bi-book" />  About Us</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to='/contact-us' className="text-decoration-none nav-link text-dark">
                                    <i className="bi bi-telephone" />  Contact Us</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle text-dark" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-lightbulb" />   Our Other Products
                                </a>
                                <ul className="dropdown-menu py-0">
                                    <li className="border-bottom mx-4">
                                        <a className="dropdown-item" href="https://www.365build.in/" target="_blank" rel="noreferrer">
                                            <span className="purple">ReadymixERP </span> <br />
                                            Cloud based solution for your construction needs
                                        </a>
                                    </li>
                                    <li className="border-bottom mx-4">
                                        <a className="dropdown-item" href="https://www.365build.ai/">
                                            <span className="purple">EBNavigator</span> <br />
                                            An AI to help with your ewaybills
                                        </a>
                                    </li>
                                    <li className="mx-4">
                                        <a className="dropdown-item" href="https://app.sygnal.in/">
                                            <span className="purple">Sygnal</span><br />
                                            Optimise your field sales workforce
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <ul className="d-flex navbar-nav mb-2 mb-md-0">
                            <li className="nav-item">
                                <NavLink to='https://app.e-waybill.in/#/login' className="text-decoration-none text-dark nav-link">
                                    <i className="bi bi-laptop" />  Login</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header >
    )
}
export default Navbar
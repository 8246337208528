export const stats = [
    {
        start: 900000,
        end: 1000000,
        duration: 10,
        description: "api calls per day",
    },
    {
        start: 90000,
        end: 100000,
        duration: 10,
        description: "einvoice generation per day",
    },
    {
        start: 90000,
        end: 200000,
        duration: 10,
        description: "ewaybills updated per day",
    },
    {
        start: 90000,
        end: 100000,
        duration: 10,
        description: "ewaybills generated per day",
    },
]
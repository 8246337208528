import { stats } from "../Data/Stats"
import CountUp from 'react-countup'

const Stats = () => {
    return (
      <div className='fs-5 fw-light lead d-flex justify-content-around flex-wrap'>
        {stats.map((stat, index) => (
          <div key={index} className='border rounded col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 align-content-center shadow mb-3 p-4'>
            <div className='purple fw-bold me-1'>
              <CountUp start={stat.start} end={stat.end} duration={stat.duration} /> +
            </div>
            {stat.description}
          </div>
        ))}
      </div>
    )
  }
  
  export default Stats